











































































import {Component, Vue} from "vue-property-decorator";
import Config from "@/models/Config";
import ConfigService from "@/services/ConfigService";
import MetaInfo from "vue-meta"

@Component({
    metaInfo(): MetaInfo {
        return {
            title: "Equipo Directivo - IES Europa",
            htmlAttrs: {
                lang: 'es'
            },
            meta: [
                {charset: 'utf-8'},
                {
                    name: 'description',
                    content: 'El equipo directivo está siempre a disposición del profesorado, del alumnado, padres y de toda la comunidad educativa.'
                },
                {name: 'viewport', content: 'width=device-width, initial-scale=1'},
                {property: 'og:title', content: "Equipo Directivo - IES Europa"},
                {property: 'og:site_name', content: 'IES Europa'},
                {property: 'og:type', content: 'page'}
            ]
        };
    }
})
export default class ManagementTeamView extends Vue {
    teachers: any = []

    headers = [
        { text: 'Cargo', value: 'position' },
        { text: 'Nombre', value: 'name' },
        { text: 'Correo Electrónico', value: 'email' },
    ]

    contactos1 = [
        { title: "Horario del centro:", content: "De 8:30 a 14:40" },
        { title: "Horario de Secretaría:", content: "De 9:00 a 14:00" },
        { title: "Email centro:", content: "30001230@murciaeduca.es" }
    ]

    contactos2 = [

        { title: "Cita previa:", content: "30001230@murciaeduca.es"},
        { title: "Teléfono:", content: "968 449100" }

    ]


    created() {
        this.refresh()
    }

    async refresh() {
        let list: Config[] = []
        await ConfigService.getConfigTeachers(this, list)
        list.forEach(v => {
            const values: any = v.value!.split(",")
            this.teachers.push({
                id: v.id,
                position: values[0],
                name: values[1],
                email: values[2]
            })
        })
    }
}
